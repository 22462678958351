import { logger } from "../../utilities/logger";
import { ENVIRONMENT } from "../../config/environment";
import { HEADER_KEYS } from "../../config/config";

const AUTH_URL_HEADERS = new Headers();
// The values are from https://gitlab.com/poppulo/engineering/domains/feeds/integrations/integrations-manager/integrations-manager-gql-gateway/-/blob/fd427b1b805877cc139cb2b35ec04025671054dc/packages/integrations-management-gql-gw-service/src/services/auth-context-enum.ts
export const INTEGRATION_TYPE = "TEAMS_FX";
AUTH_URL_HEADERS.set(HEADER_KEYS.IntegrationType, INTEGRATION_TYPE);

export async function fetchPlatformAccessToken(azureActiveDirectoryToken: string) {
  AUTH_URL_HEADERS.set(HEADER_KEYS.AzureActiveDirectoryToken, azureActiveDirectoryToken);
  logger.debug(`Fetching Platform API token`, { azureActiveDirectoryToken });
  const response = await fetch(ENVIRONMENT.AUTH_URL, {
    method: "GET",
    headers: AUTH_URL_HEADERS,
  });
  if (response.status === 200) {
    // Only when the status is 200 we return the response.
    return response;
  }
  const responseText = await response.text();
  throw new Error(`Got ${response.status}:${responseText} while fetching Platform API token`);
}
