import { useCallback, useEffect, useState } from "react";
import { createPlatformApiBaseUrlFromResponseHeaders } from "../utilities/base-url";
import { logger } from "../../utilities/logger";
import { useVisibilityChangeRetry } from "../../hooks/use-visibility-change-retry";
import { fetchPlatformAccessToken } from "../utilities/fetch-platform-access-token";
import { useExponentialRetry } from "../../hooks/use-exponential-retry";
import { Nullable } from "../../utilities/common-types";

interface IOauthTokenResponse {
  access_token: string;
  scope: string;
  token_type: string;
  // The property expires_in represents the token expiry time in seconds. Here, 3600 seconds is 1 hour.
  expires_in: number;
}

export function usePlatformApiToken(azureActiveDirectoryToken: Nullable<string>) {
  const [error, setError] = useState<Nullable<string>>(null);
  const [token, setToken] = useState<Nullable<string>>(null);
  const [tokenExpiryDateTime, setTokenExpiryDateTime] = useState<Nullable<Date>>(null);
  const [platformApiBaseUrl, setPlatformApiBaseUrl] = useState<Nullable<string>>(null);

  const getPlatformApiToken = useCallback(async () => {
    if (!azureActiveDirectoryToken || azureActiveDirectoryToken === "") {
      return null;
    }
    return fetchPlatformAccessToken(azureActiveDirectoryToken);
  }, [azureActiveDirectoryToken]);

  const {
    response: tokenResponse,
    fetchFunction: fetchToken,
    error: fetchTokenError,
  } = useExponentialRetry<Nullable<Response>>(getPlatformApiToken, {
    debugName: "PlatformApiToken",
    startOnInitialize: false,
  });

  useVisibilityChangeRetry(fetchToken, tokenExpiryDateTime, { debugName: "PlatformApiToken" });

  useEffect(() => {
    const extractTokenFromResponse = async () => {
      if (tokenResponse && !tokenResponse.bodyUsed) {
        let accessToken: Nullable<string> = null;
        let accessTokenExpiry: Nullable<Date> = null;
        let accessTokenError: Nullable<string> = null;
        try {
          const oauthResponse: IOauthTokenResponse = await tokenResponse.json();
          if (!oauthResponse.access_token) {
            throw new Error(
              `Could not extract access token from Platform API auth token response: ${JSON.stringify(oauthResponse)}`,
            );
          }
          const platformApiTokenExpiry = new Date(Date.now() + oauthResponse.expires_in * 1000);
          logger.debug(`Platform API token expiry`, { platformApiTokenExpiry });
          accessToken = oauthResponse.access_token;
          accessTokenExpiry = platformApiTokenExpiry;
        } catch (extractTokenFromResponseError) {
          logger.error(`Extracting Platform API token failed with error`, { extractTokenFromResponseError });
          accessTokenError = `Extracting PlatformApiToken failed with error ${extractTokenFromResponseError}`;
        }
        setToken(accessToken);
        setTokenExpiryDateTime(accessTokenExpiry);
        setError(accessTokenError);
      }
    };

    void extractTokenFromResponse();
  }, [tokenResponse]);

  useEffect(() => {
    const extractBaseUrlFromResponse = async () => {
      if (tokenResponse) {
        const platformApiBaseUrlFromResponse = createPlatformApiBaseUrlFromResponseHeaders(tokenResponse.headers);
        setPlatformApiBaseUrl(platformApiBaseUrlFromResponse);
      }
    };

    void extractBaseUrlFromResponse();
  }, [tokenResponse]);

  useEffect(() => {
    if (azureActiveDirectoryToken && azureActiveDirectoryToken !== "") {
      void fetchToken();
    }
  }, [azureActiveDirectoryToken, fetchToken]);

  useEffect(() => {
    if (fetchTokenError) {
      setError(`Fetching Platform API Token failed with error ${fetchTokenError}`);
    } else {
      setError(null);
    }
  }, [fetchTokenError]);

  return { error, token, platformApiBaseUrl };
}
