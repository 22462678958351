import { ENVIRONMENT } from "../../config/environment";
import { AWS_REGION } from "../../config/config";

export function createPlatformApiBaseUrlFromResponseHeaders(responseHeaders: Headers) {
  const awsRegion = (responseHeaders.get("x-amzn-region") ?? "").toLowerCase() as AWS_REGION;

  if (!awsRegion) {
    throw new Error("Unable to set base API URL as missing x-amzn-region header");
  }

  if (!Object.values(AWS_REGION).includes(awsRegion)) {
    throw new Error(`Unexpected or missing AWS Region: ${awsRegion}`);
  }

  return ENVIRONMENT.PLATFORM_API_BASE_URL_TEMPLATE.replace("REGION", awsRegion === AWS_REGION.EU_WEST_1 ? "eu" : "us");
}
