import { useCallback, useEffect, useRef, useState } from "react";
import { errorChecker } from "../utilities/error-checker";
import { AbortError, pRetry } from "../utilities/retry-request";
import { logger } from "../utilities/logger";
import { Nullable } from "../utilities/common-types";

type Options = {
  debugName?: string;
  startOnInitialize?: boolean;
};

const DEFAULT_OPTIONS: Options = {
  debugName: "useExponentialRetry",
  startOnInitialize: true,
};

export function useExponentialRetry<T>(retryableFunction: () => PromiseLike<T>, options?: Options) {
  const { debugName, startOnInitialize }: Options = { ...DEFAULT_OPTIONS, ...options };
  const [error, setError] = useState<Nullable<string>>(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<Nullable<T>>(null);
  const abortControllerRef = useRef<Nullable<AbortController>>(null);

  const fetchFunction = useCallback(async () => {
    const controllerSignal = abortControllerRef.current?.signal;
    if (controllerSignal?.aborted) {
      logger.debug(`[${debugName}] fetchFunction aborted.`);
      return;
    }
    setLoading(true);
    let pRetryResponseError: Nullable<string> = null;
    try {
      const pRetryResponse = await pRetry<T>(retryableFunction, { debugName, signal: controllerSignal });
      setResponse(pRetryResponse);
      setError(pRetryResponseError);
      setLoading(false);
    } catch (fetchError) {
      const correctedFetchError = errorChecker(fetchError);
      // We are not interested in the AbortError as it is expected. We only want to handle other errors.
      // If the error is not an AbortError, we set the error state.
      if (!(correctedFetchError instanceof AbortError)) {
        pRetryResponseError = correctedFetchError.message;
        setLoading(false);
        setError(pRetryResponseError);
      }
    }
  }, [debugName, retryableFunction]);

  useEffect(() => {
    const controller = new AbortController();
    if (abortControllerRef.current && !abortControllerRef.current.signal.aborted) {
      abortControllerRef.current.abort(new AbortError("Aborted"));
    }
    abortControllerRef.current = controller;

    if (startOnInitialize) {
      void fetchFunction();
      logger.debug(`[${debugName}] Starting operation.`);
    }

    return () => {
      logger.debug(`[${debugName}] Cleaning up.`);
      controller.abort(new AbortError("Aborted"));
    };
  }, [debugName, startOnInitialize, fetchFunction]);

  return { loading, error, response, fetchFunction };
}
