import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app/app";
import * as Sentry from "@sentry/react";
import { logger } from "./utilities/logger";
import { ENVIRONMENT } from "./config/environment";
import packageJson from "../package.json";
import { SESSION_STORAGE_KEY } from "./config/config";

Sentry.init({
  dsn: ENVIRONMENT.SENTRY_DSN,
  environment: ENVIRONMENT.ENV,
  integrations: [],
  tracesSampleRate: ENVIRONMENT.SENTRY_TRACES_SAMPLE_RATE,
});

logger.info(`App version: ${packageJson.version}`);
Sentry.setTag("version", packageJson.version);

sessionStorage.setItem(SESSION_STORAGE_KEY.CHANNEL_ID, JSON.stringify(ENVIRONMENT.CHANNEL_ID));

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
