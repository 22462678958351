import { logger } from "./logger";
import { operation, OperationOptions } from "retry";
import { errorChecker } from "./error-checker";

/**
 * Custom error class for aborting operations
 */
export class AbortError extends Error {
  constructor(message: string) {
    super();
    this.name = "AbortError";
    this.message = message;
  }
}

type PRetryOptions = Partial<OperationOptions> & {
  signal?: AbortSignal;
  debugName?: string;
};

export const DEFAULT_OPERATION_OPTIONS: OperationOptions = {
  retries: 4,
  factor: 3,
  minTimeout: 1000,
  maxTimeout: 3 * 1000,
  randomize: true,
};

/**
 * This function retries the given function until it succeeds or until the abort signal is triggered.
 * The function will retry 5 times with a factor of 3, a minimum timeout of 1 second, and a maximum timeout of 3 seconds.
 * The function will also randomize the timeout.
 * If the function fails after 5 retries, the function will throw the error.
 * If the abort signal is triggered, the function will throw an AbortError.
 *
 * @param {() => PromiseLike<T> | T} retryFunction - The function to retry
 * @param {PRetryOptions} options - Options for the retry operation
 * @returns {Promise<T>} - A promise that resolves with the result of the retryFunction or rejects with an error
 */
export async function pRetry<T>(retryFunction: () => PromiseLike<T> | T, options?: PRetryOptions): Promise<T> {
  let { signal: abortSignal, debugName, ...retryOptions } = options ?? {};

  if (!debugName) {
    debugName = retryFunction.name ?? "pRetry";
  }

  let modifiedOptions: OperationOptions = {
    ...DEFAULT_OPERATION_OPTIONS,
    ...retryOptions,
  };

  const retryOperation = operation(modifiedOptions);

  return new Promise((resolve, reject) => {
    const abortHandler = () => {
      retryOperation.stop();
      logger.debug(`[${debugName}] Operation aborted.`);
      reject(abortSignal?.reason ?? new AbortError(`[${debugName}] Operation aborted.`));
    };

    if (abortSignal && !abortSignal.aborted) {
      abortSignal.addEventListener("abort", abortHandler, { once: true });
    }

    const cleanUp = () => {
      abortSignal?.removeEventListener("abort", abortHandler);
      retryOperation.stop();
    };

    retryOperation.attempt(async (attemptNumber) => {
      try {
        const result = await retryFunction();
        resolve(result);
        cleanUp();
      } catch (error) {
        const retryError = errorChecker(error);

        if (!retryOperation.retry(retryError)) {
          logger.debug(`[${debugName}] Request failed. No more retries left.`, { retryError });
          cleanUp();
          reject(retryOperation.mainError());
        } else {
          logger.debug(`[${debugName}] Request failed. Trying again.`, { attemptNumber, retryError });
        }
      }
    });
  });
}
