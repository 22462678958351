import { useEffect, useState } from "react";
import { app } from "@microsoft/teams-js";
import { setUser } from "@sentry/react";
import { Nullable } from "../../utilities/common-types";

export function useTeamsContext(isInitialized: boolean) {
  const [error, setError] = useState<Nullable<string>>(null);
  const [contextData, setContextData] = useState({ instanceId: "", appLocale: "", subPageId: "" });

  useEffect(() => {
    const getAppContext = async () => {
      try {
        const appContext = await app.getContext();
        setContextData({
          instanceId: appContext.page.id?.toString() ?? "",
          appLocale: appContext.app.locale?.toString() ?? "",
          subPageId: appContext.page.subPageId?.toString() ?? "",
        });
        setUser({
          tenantId: appContext.user?.tenant?.id,
          userId: appContext.user?.id,
        });
        setError(null);
      } catch (appContextError) {
        setError(`getting app context failed with error: ${appContextError}`);
      }
    };
    if (isInitialized) {
      void getAppContext();
    }
  }, [isInitialized]);

  return {
    error,
    instanceId: contextData.instanceId,
    appLocale: contextData.appLocale,
    subPageId: contextData.subPageId,
  };
}
