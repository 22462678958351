import Styles from "./spinner.module.css";
import { ReactComponent as SpinnerLight } from "./assets/spinner-light.svg";

export const TEST_ID = {
  container: "spinner-container",
};
export function Spinner() {
  return (
    <div data-testid={TEST_ID.container} className={Styles.posCenter}>
      <SpinnerLight className={Styles.loader} />
    </div>
  );
}
