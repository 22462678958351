import { useEventListener, useTimeout } from "usehooks-ts";
import { useEffect, useRef, useState } from "react";
import { logger } from "../utilities/logger";
import { Nullable } from "../utilities/common-types";

type RetryFunctionType = () => Promise<void> | void;

type Options = {
  debugName: string;
};

function calculateRetryIntervalInMs(retryDateTime: Nullable<Date>): Nullable<number> {
  if (!retryDateTime) {
    return null;
  }
  const diff = retryDateTime.getTime() - Date.now();
  if (diff > 0) {
    return diff;
  }
  return 0;
}

const DEFAULT_OPTIONS: Options = {
  debugName: "useVisibilityChangeRetry",
};

export function useVisibilityChangeRetry(
  retryFunction: RetryFunctionType,
  retryDateTime: Nullable<Date>,
  options: Options,
): void {
  const { debugName }: Options = { ...DEFAULT_OPTIONS, ...options };
  const [retryIntervalInMs, setRetryIntervalInMs] = useState<Nullable<number>>(null);
  const [isVisible, setIsVisible] = useState(true);
  const documentRef = useRef<Document>(document);

  const onVisibilityChange = () => {
    const isHidden = documentRef.current.hidden;
    setIsVisible(!isHidden);
  };

  useEffect(() => {
    let calculatedRetryIntervalInMs: Nullable<number> = null;

    if (isVisible) {
      // Only calculate the time if the tab is visible
      calculatedRetryIntervalInMs = calculateRetryIntervalInMs(retryDateTime);
    }

    logger.debug(`[${debugName}] calculating retry interval`, {
      retryDateTime,
      calculatedRetryIntervalInMs,
      nextRetry:
        calculatedRetryIntervalInMs && !Number.isNaN(calculatedRetryIntervalInMs)
          ? new Date(Date.now() + calculatedRetryIntervalInMs)
          : null,
      visibility: isVisible,
    });
    setRetryIntervalInMs(calculatedRetryIntervalInMs);
  }, [retryDateTime, isVisible, debugName]);

  useTimeout(retryFunction, retryIntervalInMs);

  useEventListener("visibilitychange", onVisibilityChange, documentRef);
}
