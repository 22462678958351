/*eslint no-console: ["off"] */

import { ENVIRONMENT } from "../config/environment";

export const LogLevels = {
  info: "info",
  error: "error",
  warn: "warn",
  debug: "debug",
} as const;

class Logger {
  private readonly name = "poppulo-ms-teams-react-app";
  private static instance: Logger;

  private constructor() {
    // Do nothing.
  }

  private getConsoleArguments(message: string, extra: Record<string, any>): any[] {
    let stringSubstitutionsTemplate = "[%s] %s";
    const formattedMessage: any = [this.name, message.toString()];
    if (Object.keys(extra).length > 0) {
      stringSubstitutionsTemplate += " %o";
      formattedMessage.push(extra);
    }
    return [stringSubstitutionsTemplate, ...formattedMessage];
  }

  public static getInstance(): Logger {
    if (typeof Logger.instance === "undefined") {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  private logToConsole(level: keyof typeof LogLevels, consoleArgs: any): void {
    switch (level) {
      case LogLevels.debug:
        console.debug(...consoleArgs);
        break;
      case LogLevels.error:
        console.error(...consoleArgs);
        break;
      case LogLevels.warn:
        console.warn(...consoleArgs);
        break;
      case LogLevels.info:
      default:
        console.info(...consoleArgs);
        break;
    }
  }

  debug(message: string, extra: Record<string, any> = {}): void {
    if (ENVIRONMENT.ENABLE_DEBUG) {
      const consoleArguments = this.getConsoleArguments(message, extra);
      this.logToConsole(LogLevels.debug, consoleArguments);
    }
  }

  info(message: string, extra: Record<string, any> = {}): void {
    const consoleArguments = this.getConsoleArguments(message, extra);
    this.logToConsole(LogLevels.info, consoleArguments);
  }

  error(message: string, extra: Record<string, any> = {}): void {
    const consoleArguments = this.getConsoleArguments(message, extra);
    this.logToConsole(LogLevels.error, consoleArguments);
  }

  warn(message: string, extra: Record<string, any> = {}): void {
    const consoleArguments = this.getConsoleArguments(message, extra);
    this.logToConsole(LogLevels.warn, consoleArguments);
  }
}

export const logger = Logger.getInstance();
