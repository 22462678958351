export const ENVIRONMENT = {
  CHANNEL_ID: process.env.REACT_APP_CHANNEL_ID,
  FEED_READER_ASSETS_CDN_URL: process.env.REACT_APP_FEED_READER_ASSETS_CDN_URL,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_TRACES_SAMPLE_RATE: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ?? "0.3"),
  ENV: process.env.REACT_APP_ENVIRONMENT ?? "staging",
  AUTH_URL: new URL(`https://${process.env.REACT_APP_AUTH_URL}`),
  ENABLE_DEBUG: process.env.REACT_APP_ENABLE_DEBUG === "true",
  PLATFORM_API_BASE_URL_TEMPLATE: `${process.env.REACT_APP_PLATFORM_API_BASE_URL_TEMPLATE}`,
};
