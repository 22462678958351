import { useEffect, useState } from "react";
import { app } from "@microsoft/teams-js";
import { MESSAGE_ORIGINS } from "../../config/config";
import { logger } from "../../utilities/logger";
import { Nullable } from "../../utilities/common-types";

export function useTeamsAppInitialization() {
  const [appInitialized, setAppInitialized] = useState(false);
  const [error, setError] = useState<Nullable<string>>(null);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        await app.initialize(MESSAGE_ORIGINS);
        setAppInitialized(true);
        app.notifyAppLoaded();
        app.notifySuccess();
        setError(null);
        logger.debug("Teams app initialized");
      } catch (initializeAppError) {
        setError(`app initialization failed with error:${initializeAppError}`);
      }
    };
    void initializeApp();
  }, []);

  return { appInitialized, error };
}
