/**
 * This function checks if the provided value is an instance of Error.
 * If it is, and a message is provided, it returns a new Error with the provided message and the original error as the cause.
 * If it is, and no message is provided, it returns the original error.
 * If it is not, it returns a new TypeError with a default message and the original value as the cause.
 *
 * @param {any} error - The value to check.
 * @param {string} [message] - The message to use if creating a new Error.
 * @returns {Error} The original error if it is an instance of Error and no message is provided, otherwise a new Error or TypeError.
 */
export function errorChecker(error: any, message?: string) {
  if (error instanceof Error) {
    if (message) {
      return new Error(message, { cause: error });
    }
    return error;
  }
  let typeErrorMessage = "Non-error was thrown. You should only throw errors.";
  if (message) {
    typeErrorMessage += ` [${message}]`;
  }
  return new TypeError(typeErrorMessage, { cause: error });
}
